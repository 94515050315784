
import SupportMessage from "@/components/SupportMessage"
import { CameraStatus } from "@evercam/shared/types/camera"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import { useNuxtApp } from "#app"
import Vue from "vue"
import NdaIcon from "@/components/NdaIcon"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import BatteryLevelIcon from "@evercam/shared/components/BatteryLevelIcon"
import { mapStores } from "pinia"
import { useBatteryReadingsStore } from "@/stores/batteryReadings"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default Vue.extend({
  meta: {
    pageId: AnalyticsEventPageId.Project,
  },
  components: {
    NdaIcon,
    BatteryLevelIcon,
    EvercamLoadingAnimation,
    SupportMessage,
  },
  middleware({ redirect, from, $permissions }) {
    const accountStore = useAccountStore()
    if (!accountStore.token) {
      accountStore.redirectUrl = from.fullPath

      return redirect("/v2/users/signin")
    }

    const projectStore = useProjectStore()

    //Custom case for projects with no cameras. TODO// DELETE WHEN Projects supports no cameras.
    if ($permissions.project?.has.noCameras()) {
      const featureFlags = projectStore.selectedProject.featureFlags
      let targettedApp = ""

      if (featureFlags.includes(ProjectFeatureFlag.DroneView)) {
        targettedApp = "drone"
      } else if (featureFlags.includes(ProjectFeatureFlag.ThreeSixtyView)) {
        targettedApp = "360"
      } else if (featureFlags.includes(ProjectFeatureFlag.Bim)) {
        targettedApp = "bim"
      } else if (
        featureFlags.includes(ProjectFeatureFlag.GateReport) ||
        featureFlags.includes(ProjectFeatureFlag.GateReportAnprBased)
      ) {
        targettedApp = "gate-report"
      }
      if (!targettedApp) {
        return
      }

      const projectFeatureRoute = `${projectStore?.projectRoute}/${targettedApp}`

      return redirect(`${projectFeatureRoute}`)
    }

    if (projectStore?.selectedProjectCameras.length === 1) {
      return redirect(
        `${projectStore?.projectRoute}/${projectStore?.selectedProjectCameras[0].id}`
      )
    }
  },
  data: () => ({
    cameras: [],
  }),
  head() {
    return {
      title: `${
        this.projectStore.selectedProjectName || "Evercam"
      } | Project Dashboard`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useBatteryReadingsStore,
      useProjectStore,
      useCameraStore,
      useAccountStore
    ),
    isOffline() {
      return useNuxtApp().vue2App.isOffline
    },
    isUnderNda() {
      return this.projectStore.selectedProject?.featureFlags?.includes(
        ProjectFeatureFlag.NdaMc
      )
    },
    items() {
      let cameras = this.projectStore.selectedProjectCameras
      if (!this.cameras || !this.accountStore.token) {
        return []
      }

      return cameras.reduce(
        (acc, c) => [
          ...acc,
          {
            thumbnail: c.largeThumbnailUrl,
            title: c.name,
            exid: c.id,
            status: c.status,
            to: `/v2/projects/${this.routeProjectExid}/${c.id}${
              [CameraStatus.Decommissioned, CameraStatus.OnHold].includes(
                c.status
              )
                ? "/recordings"
                : ""
            }`,
          },
        ],
        []
      )
    },
    routeProjectExid() {
      const camelizedKeys = camelizeKeys(this.$route.params) as {
        projectExid: string
      }

      return camelizedKeys?.projectExid
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    isOfflineCameraStatus(status: CameraStatus): boolean {
      return status === CameraStatus.Offline
    },
    isWaitingCameraStatus(status: CameraStatus): boolean {
      return status === CameraStatus.Waiting
    },
    getCameraThumbnail(item) {
      if (item.status === CameraStatus.Waiting) {
        return require("~/assets/img/waiting_for_activation.jpg")
      }

      return item.thumbnail
    },
  },
})
